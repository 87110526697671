<template>
    <div>
        <div class="echarts_line" :ref="className" :style="{ height: (this.height || 530) + 'px' }">
            <div class="nodata" v-if="(chartData || {data: []}).data.length<=0">暂无数据</div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import theme from './roma';

    echarts.registerTheme('roma', theme.roma);

    export default {
        name: 'ELine',
        props: ['height', 'chartData', 'className', 'legend'],
        data() {
            return {
                myChart: null,
            };
        },
        mounted() {
            this.drawChart();
        },
        methods: {
            resize() {
                this.myChart && this.myChart.resize();
            },
            drawChart() {
                if ((this.chartData || {data: []}).data.length <= 0) {
                    return;
                }

                this.$nextTick(() => {
                    this.myChart = echarts.init(this.$refs[this.className], 'roma');
                    window.onresize = () => {
                        this.resize();
                    };
                    const resizeObserver = new ResizeObserver(entries => {
                        this.resize();
                    });
                    resizeObserver.observe(this.$parent.$el);

                    // 指定图表的配置项和数据
                    let option = {
                        legend: {
                            data: []
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985',
                                },
                            },
                        },
                        grid: {
                            left: '3%',
                            right: '3%',
                            bottom: '3%',
                            containLabel: true,
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: false,
                                data: this.chartData.xAxis,
                                nameRotate: 30
                            },
                        ],
                        yAxis: [
                            {
                                type: 'value',
                            },
                        ],
                        series: [],
                    };
                    let colors = [
                        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: 'rgba(0,163,255, 1)'},
                            {offset: 0.2, color: 'rgba(0,163,255, 0.1)'},
                            {offset: 1, color: 'rgba(0,163,255, 0)'},
                        ]),
                        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: 'rgba(64,192,69, 1)'},
                            {offset: 0.2, color: 'rgba(64,192,69, 0.1)'},
                            {offset: 1, color: 'rgba(64,192,69, 0)'},
                        ]),
                        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: 'rgba(255,126,22, 1)'},
                            {offset: 0.2, color: 'rgba(255,126,22, 0.1)'},
                            {offset: 1, color: 'rgba(255,126,22, 0)'},
                        ]),
                        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: 'rgba(103,219,248, 1)'},
                            {offset: 0.2, color: 'rgba(103,219,248, 0.1)'},
                            {offset: 1, color: 'rgba(103,219,248, 0)'},
                        ]),
                        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: 'rgba(243,201,107, 1)'},
                            {offset: 0.2, color: 'rgba(243,201,107, 0.1)'},
                            {offset: 1, color: 'rgba(243,201,107, 0)'},
                        ]),
                    ];
                    this.chartData.data.forEach((item, idx) => {
                        this.legend && option.legend.data.push(item.title);
                        option.series.push({
                            name: item.title,
                            type: 'line',
                            smooth: true,
                            lineStyle: {
                                width: 3,
                            },
                            showSymbol: true,
                            areaStyle: {
                                color: colors[idx],
                            },
                            // emphasis: {
                            // 	focus: 'series',
                            // },
                            data: item.list,
                        });
                    });
                    this.myChart.setOption(option);
                });
            },
        },
        watch: {
            chartData: {
                deep: true,
                handler() {
                    console.log('watch chartData...');
                    this.drawChart();
                },
            },
        },
    };
</script>

<style scoped lang="less">
    .echarts_line {
        position: relative;
        width: 100%;
        .nodata {
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 24px;
            color: #cccccc;
        }
    }
</style>
