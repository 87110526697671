<template>
    <div class="relative width_100_percent order_statistics_panel">
        <dataTitle>
            <dateSearch @search="onSearchDetail"></dateSearch>
        </dataTitle>

        <dataTitle class="mt_20" slot="title" title="订单统计"></dataTitle>
        <div class="mt_10 relative flex flex_wrap align_center card_panel">
            <itemCard title="订单数量" :val="totalOrder" unitAfter="(个)"></itemCard>
            <itemCard title="订单总额" :decimalPlaces="2" :val="totalMoney"></itemCard>
            <itemCard title="退款订单" :val="refundOrder" unitAfter="(个)"></itemCard>
            <itemCard title="退款总额" :decimalPlaces="2" :val="refundMoney"></itemCard>
        </div>

        <div class="mt_20 relative width_100_percent flex flex_wrap align_start space_between detail_panel">
            <div class="relative detail_item">
                <dataTitle slot="title" title="订单趋势图"></dataTitle>
                <div class="relative bar_panel">
                    <eline className="order_statistics_detail_line" :legend="true" :height="500" :chartData="orderTrendData"></eline>
                </div>
            </div>
            <div class="relative detail_item">
                <dataTitle slot="title" title="订单排行榜"></dataTitle>
                <div class="relative width_100_percent table_panel">
                    <Table stripe class="width_100_percent mt_10" :columns="orderRankColumn" :data="orderRankList">
                        <template slot-scope="{ index }" slot="rankNo">
                            {{ index + 1 }}
                        </template>
                    </Table>
                </div>
            </div>
        </div>

        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import { reqOrderStat } from '@/lib/request/auth2';
import dataTitle from './comp/dataTitle';
import dateSearch from './comp/dateSearch';
import eline from './comp/echarts/line';
import itemCard from './comp/itemCard';
import { isFunction } from '@/lib/util';

const moDef = {
    // order-订单统计 orderList-订单记录， orderTop-订单Top
    order: 'order',
    orderList: 'orderList',
    orderTop: 'orderTop',
};

export default {
    name: 'orderStatistics',
    components: {
        dataTitle,
        dateSearch,
        eline,
        itemCard,
    },
    data() {
        return {
            detail: {},
            totalOrder: 0,
            totalMoney: 0,
            refundOrder: 0,
            refundMoney: 0,
            orderTrendData: { xAxis: [], data: [] },
            orderRankColumn: [
                { slot: 'rankNo', title: '排名' },
                { key: 'ctime', title: '日期' },
                { key: 'nums', title: '订单数量' },
            ],
            orderRankList: [],
        };
    },
    mounted() {
        this.getDetail();
    },
    watch: {
        //
    },
    methods: {
        reqData(params, cb) {
            this.showAjaxLoading();
            reqOrderStat(params)
                .then(res => {
                    let data = res?.data || {};
                    isFunction(cb) && cb(data);
                })
                .catch(() => {
                    isFunction(cb) && cb({});
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },

        //获取详情
        getDetail() {
            //
        },
        //查询明细
        onSearchDetail(arrDate) {
            this.showAjaxLoading();
            let timeDate = '';
            if (!!arrDate[0] && !!arrDate[1]) {
                timeDate = JSON.stringify([arrDate[0], arrDate[1]]);
            }

            this.reqData({ mo: moDef.order, timeDate }, row => {
                let data = row.row || {};
                this.totalOrder = data.totalOrder || 0;
                this.totalMoney = data.totalMoney || 0;
                this.refundOrder = data.refundOrder || 0;
                this.refundMoney = data.refundMoney || 0;
            });

            this.reqData({ mo: moDef.orderList, timeDate }, data => {
                let orderList = data.orderList || [];
                let orderListLen = orderList.length;

                orderListLen > 0 &&
                    (this.orderTrendData = {
                        xAxis: orderList.map(info => info.ctime || ''),
                        data: [{ title: '下单数量', list: orderList.map(info => info.nums || 0) }],
                    });
                console.log('orderList', this.orderTrendData);
            });

            this.reqData({ mo: moDef.orderTop, timeDate }, data => {
                this.hideAjaxLoading();
                let orderTop = data.orderTop || [];
                this.orderRankList = orderTop;
            });
        },
    },
};
</script>

<style></style>

<style lang="less" scoped>
.order_statistics_panel {
    .card_panel {
        gap: 15px;
        .card {
            flex: 1;
        }
    }

    .detail_panel {
        gap: 20px;
        .detail_item {
            width: 48%;
            min-width: 500px;
        }
    }
}
</style>
